import logo from './logo.svg';
import './App.css';
import { useState,useEffect } from 'react';
import Sidebar from './component/sidebar';
import {HashRouter,Route,Routes, Navigate  } from 'react-router-dom';
import UserAdd from './component/payment';
import Truck from './component/history';
import Bilty_LR from './component/bilty_lr';
import Invoice from './component/invoice';
import Invoice2 from './component/invoice2';


  function App() {


    return (
      <div className="App">
      
        <HashRouter>
          <Routes>
           
              <Route path='/' element={<UserAdd />} />
              <Route path='/invoice' element={<Invoice />} />
              <Route path='/invoice2' element={<Invoice2 />} />
              <Route path='/history' element={<Truck />} />

        </Routes>
        </HashRouter>


      </div>
  );
}

export default App;
