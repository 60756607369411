import React, { useEffect, useState,useRef} from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import Sidebar from './sidebar';
import axios from 'axios';
import Invoice from './invoice2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoSearch } from "react-icons/io5";
function Truck() {
   
    const [invoice, setInvoice] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const invoiceRef = useRef(null);
    const [invoiceid, setInvoiceid] = useState('')
    const [search, setSearch] = useState('')
    const getinvoice = (e) => {
        axios.get('https://invoice.pksinfotechpltd.com/backend/public/getinvo')
            .then((response) => {

                setInvoice(response.data);
               

            })
            .catch((error) => {
                console.log(error);
            })
    }

    const OnDownload = (id) => {
        
        setInvoiceid(id);
        setIsModalOpen(true); 
        
    }
    const onClose = () => {
     
        setIsModalOpen(false); 
        
    }
  
    const generatePDF = () => {
        const element = invoiceRef.current;
        const img = document.getElementById('invoiceImage');  // Reference to the image

        if (!element) {
            console.error('Invoice element not found.');
            return;
        }

        if (!img.complete) {
            // Wait for the image to load if it's not loaded yet
            img.onload = () => {
                convertToPDF(element); // Call the PDF generation function after the image loads
            };
        } else {
            // Image is already loaded, proceed with PDF generation
            convertToPDF(element);
        }

        const element2 = document.getElementById('targetLR');
        if (!element) {
            console.error('Element with id "targetLR" not found.');
            return;
        }

    };
    const convertToPDF = (element) => {

        const opt = {
            margin: 0.1, // Adjust margins as needed
            filename: 'LR.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true }, // Increase scale for better quality
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', pageBreak: { mode: 'avoid-all' } }, // Avoid breaking content if possible
            pagebreak: { mode: 'avoid-all' } // Avoid breaking content if possible
        };
        html2pdf().from(element).set(opt).save();
    }
    const generatePDF2 = () => {
        const element = invoiceRef.current;
        const img = document.getElementById('invoiceImage');

        if (!element) {
            console.error('Invoice element not found.');
            return;
        }

        if (!img.complete) {
            img.onload = () => {
                convertToPDF2(element);
            };
        } else {
            convertToPDF2(element);
        }

        const element2 = document.getElementById('targetLR');
        if (!element2) {
            console.error('Element with id "targetLR" not found.');
            return;
        }
    };

    const convertToPDF2 = (element) => {
        const opt = {
            margin: 0.1,
            filename: 'LR.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', pageBreak: { mode: 'avoid-all' } },
            pagebreak: { mode: 'avoid-all' }
        };

        // Generate PDF as a Blob
        html2pdf().from(element).set(opt).output('blob').then((pdfBlob) => {
            // Create a URL for the Blob
            const url = URL.createObjectURL(pdfBlob);

            // Open the URL in a new window
            const printWindow = window.open(url, '_blank');

            if (printWindow) {
                // Wait for the PDF to load and then open the print dialog
                printWindow.onload = () => {
                    printWindow.print();
                };
            } else {
                console.error('Failed to open print window.');
            }
        });
    };

    useEffect(() => {
        
        getinvoice();
    }, [])

    const filteredUsers = invoice?.filter(user =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.fname.toLowerCase().includes(search.toLowerCase()) ||
        user.passport.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.mobile.includes(search)
    );
    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };
    return (    
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"

            />
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
             
                <div className='m-0 p-0 w-100'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>History (Chandigarh Office)</h1>
                    </Container>

                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md overflowy' style={{ minHeight: '82vh', maxHeight:"82vh"}}>
                        <div className='d-flex justify-content-end align-items-center mt-4 pe-3 border-bottom-1 '>
                         
                            <div className='text-end'>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                        value={search}
                                        onChange={(e)=>setSearch(e.target.value)}
                                    />
                                    <InputGroup.Text id="basic-addon2"><IoSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>  
                        <div className="table-responsive px-2 pt-4">

                            <table className='table table-striped  table-hover'>
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Name</h4></th>
                                        <th><h4>Father Name</h4></th>
                                        <th><h4>DOB</h4></th>
                                        <th><h4>Passport No</h4></th>
                                        <th><h4>Email</h4></th>
                                        <th><h4>Mobile No</h4></th>
                                        <th><h4>Invoice</h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                  
                                    {filteredUsers.length > 0 ? filteredUsers.slice().reverse().map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.fname}</td>
                                            <td>{formatDate(item.dob)}</td>
                                            <td>{item.passport}</td>
                                            <td>{item.email}</td>
                                            <td>{item.mobile}</td>
                                            <td>
                                                <button className='btn bg-danger text-white fw-6' onClick={() => OnDownload(item.id)}>
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr className='pt-4 text-center'>
                                            <td colSpan={8}>
                                                <h5 className='mt-2'>No Invoice Found</h5>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>
            {/* <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="exampleModal" tabIndex="{-1}" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content border-0 ">

                        <div class="modal-header border-bottom-1 border-1 border-dark mb-3">
                            <h2 class="modal-title">Invoice</h2>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button>
                        </div>

                        <div id='targetLR' className='mx-3'>
                            <Invoice ref={invoiceRef} id={invoiceid} />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}>
                                Close
                            </button>
                            <button type="button" className="btn btn-primary" onClick={generatePDF}>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
            <Modal show={isModalOpen} onHide={onClose} size="lg" centered id="exampleModal">
                <Modal.Header closeButton className="border-bottom-1 border-1 border-dark mb-3">
                    <Modal.Title>Payment Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <button className='btn btn-primary' onClick={generatePDF}>
                        Save As Pdf
                    </button>
                    <button type="button" className="btn btn-danger" onClick={generatePDF2}>
                        Print
                    </button>
                </Modal.Footer>
                <Modal.Body className="mx-3">
                    <div id="targetLR">
                        <Invoice ref={invoiceRef} id={invoiceid} />
                    </div>
                </Modal.Body>

              
            </Modal>
        </div>
    )
}

export default Truck;