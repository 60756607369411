import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../assests/stamp.svg'
import stamp from '../assests/stam.png'
function Invoice() {
  return (
    <>
      <Container className='fw-semibold border border-1' style={{ minHeight: '100vh',maxHeight:'100vh' }} >
        <div className="d-flex align-items-center ">
          <img src={logo} alt="image" height={200} className='me-5' />
          <div className='text-center'>
            <h1 className='ms-5 border-bottom border-2 border-dark text-dark'>SUNDLASS CONSULTANTS</h1>
            <h6 className='ms-5 border-top border-2 border-dark text-dark pt-2'>A Complete Visa Counselling Services</h6>
          </div>
        </div>
        <Row className='g-3 p-3'>
          {/* //--------------------------------------------// */}
          <Col md={3}>
            <h5>RECEIPT NO : </h5>
          </Col>
          <Col md={3}>

          </Col>
          <Col md={3}>

          </Col>
          <Col md={3}>
            <h5>DATE :</h5>

          </Col>
          {/* //--------------------------------------------// */}
          <Col md={12}>
            <h5>Received with thanks from Mr./Miss/Mrs : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>

          {/* //--------------------------------------------// */}
          <Col md={12}>
            <h5>S/o,W/o,D/o,Sh : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>

          {/* //--------------------------------------------// */}
          <Col md={12}>
            <h5>The Sum Of Rupees : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          {/* //--------------------------------------------// */}
          <Col md={12}>
            <h5>On Account Of : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>

          <Col md={4}>
            <h5>By Cash/Cheque/Draft No : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={4}>
            <h5>Date : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={4}>
            <h5>Drawn At : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>

          <Col md={6}>
            <h5>Balance Payment , if any Rs : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={6}>
            <h5>(Rupees) : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={12}>
            <h5>To Be Paid On : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={12}>
            <h5>Comment If Any : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={3}>
              <div className='rounded-4 border border-2 border-dark p-2'>
                <h4>AMOUNT Rs : <span className='ms-2 border-bottom border-dark text-dark'></span></h4>
              </div>
          </Col>
          <Col md={9}>
          
          </Col>

        </Row>
        <Container fluid className='text-end border-bottom border-2 border-dark'>
          <img src={stamp} alt="image" height={150} className='me-5' />
        </Container>
        <div className='ps-4'>
          
          <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab inventore in, aliquam repellendus molestias quidem </h5>
          <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab inventore in, aliquam repellendus molestias quidem </h5>
          <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab inventore in, aliquam repellendus molestias quidem </h5>
          <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab inventore in, aliquam repellendus molestias quidem </h5>
          <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab inventore in, aliquam repellendus molestias quidem </h5>
        </div>
        <div className="container-fluid d-flex justify-content-center mt-3">
        <div className="text-center w-75">
          <h5>
            ਉਪਰ ਲਿਖਿਆ ਸਾਰਿਆ ਸ਼ਰਤਾ ਦਾ ਪੰਜਾਬੀ ਵਿਚ ਅਨੁਵਾਦ ਕਰ ਕੇ ਮੈਨੂੰ ਗਵਾਹ ਦੇ ਸਾਮਣੇ ਸਮਝੀਆਂ ਗਈਆ
            ਅਤੇ ਮੈਂ ਇਹਨਾ ਨਾਲ ਸਹਿਮਤ ਹਾਂ । ਅਤੇ ਮੈਂ ਗਵਾਹ ਦੇ ਸਾਮਣੇ ਦਸਤਖਤ ਰਿਹਾ/ਰਹੀ ਹਾਂ |
           </h5>
        </div>
        </div>

        <Row>
          <Col md={6}>
            <h5>Signature Of Customer : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={6}>
            <h5>Signature Of Witness : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
          <Col md={12}>
            <h5>Address : <span className='ms-2 border-bottom border-dark text-dark'></span></h5>
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default Invoice
