import React, { useEffect, useState } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
import logo from '../assests/stamp.svg'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
function Sidebar() {

  let location = useLocation();
  const currPage = location.pathname


  useEffect(() => {
  }, [])
  return (

    <aside className='sidebar sidebar_main m-0  h-100'>
      <Container className='text-center'>
        <img src={logo} alt="" width={190}/>
      </Container>
        <ListGroup variant="flush" className='nav ps-3 pe-2 '>
        <ListGroup.Item className={`py-0 menu_item ${currPage === '/' ? 'menu_item_active' : ''}`}>
          <NavLink to={'/'} className={`menu_link nav-img3 fs-5 ${currPage === '/' ? 'menu_link_active nav_img3_active' : ""}`}>Invoice</NavLink>
          </ListGroup.Item>
          <ListGroup.Item className={`py-0 menu_item ${currPage === '/history' ? 'menu_item_active' : ''}`}>
            <NavLink to={'/history'} className={`menu_link nav-img1 fs-5 ${currPage === '/history' ? 'menu_link_active nav_img1_active' : ""}`}>History</NavLink>
          </ListGroup.Item>
      
        </ListGroup>
    </aside>


  )
}

export default Sidebar

